exports.components = {
  "component---src-generated-pages-policy-tsx": () => import("./../../../src/generated-pages/policy.tsx" /* webpackChunkName: "component---src-generated-pages-policy-tsx" */),
  "component---src-generated-pages-product-tsx": () => import("./../../../src/generated-pages/product.tsx" /* webpackChunkName: "component---src-generated-pages-product-tsx" */),
  "component---src-generated-pages-service-tsx": () => import("./../../../src/generated-pages/service.tsx" /* webpackChunkName: "component---src-generated-pages-service-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-about-tsx": () => import("./../../../src/pages/company/about.tsx" /* webpackChunkName: "component---src-pages-company-about-tsx" */),
  "component---src-pages-company-brand-tsx": () => import("./../../../src/pages/company/brand.tsx" /* webpackChunkName: "component---src-pages-company-brand-tsx" */),
  "component---src-pages-company-careers-tsx": () => import("./../../../src/pages/company/careers.tsx" /* webpackChunkName: "component---src-pages-company-careers-tsx" */),
  "component---src-pages-get-in-touch-tsx": () => import("./../../../src/pages/get-in-touch.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

